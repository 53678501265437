<template>
  <div class="home">
    <img sizes="center" alt="TC RW logo" src="../assets/image.png">
    <HelloWorld msg="Willkommen"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'


export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
