<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/Jugendverwaltung">Jugend</router-link> |
    <router-link to="/Termine">Termine</router-link> |
    <router-link to="/Platzbelegung">Platzbelegung</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <router-view/>
  <div class="foot">
    &copy; TC RW Bad Mergentheim
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.foot, nav {
  padding: 30px;
  background: red;
  text-align: center;
  width: 100%;
  border-bottom: white;
}

.foot, nav a {
  font-weight: bold;
  color: #2c3e50;
}

.foot, nav a.router-link-exact-active {
  color: white;
}
</style>
