<template>
 <div class="NotFound">
    <h1>404</h1>
    <p>TEST</p>
 </div>

</template>
<script>
    export default{

    }
</script>
<style>

</style>