import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import JugendView from '../views/JugendVerwaltungView.vue'
import TermineView from '../views/TermineView.vue'
import PlatzBelegungView from '../views/PlatzBelegungView.vue'
import NotFound from '../views/NotFound.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/Jugendverwaltung',
    name: 'jugendverwaltung',
    component: JugendView
  },
  {
    path: '/Termine',
    name: 'termine',
    component: TermineView
  },
  {
    path: '/Platzbelegung',
    name: 'platzbelegung',
    component: PlatzBelegungView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
